<template>
	<el-dialog
		:visible.sync="visible"
		:show-close="false"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		class="role-dialog"
		width="620px">
		<span slot="title" class="dialog-title">
			<span>{{title}}</span>
			<img src="@/assets/images/close.png" alt="" @click="visible = false">
		</span>
		<el-form :model="dataForm" :rules="dataRule" label-position="top" ref="dataForm">
			<div class="flex">
				<div class="flex-1">
					<el-form-item label="经销商编码" prop="code">
						<el-input size="small" v-model="dataForm.code" placeholder="经销商编码" maxlength="32" :disabled="true"></el-input>
					</el-form-item>
					<el-form-item label="经销商简称(Advance编码)" prop="nameShort">
						<el-input size="small" v-model="dataForm.nameShort" placeholder="经销商简称(Advance编码)" maxlength="32" :disabled="disable"></el-input>
					</el-form-item>
					<el-form-item label="区域" prop="rangeId">
						<el-select size="small" v-model="dataForm.rangeId" filterable placeholder="请选择区域" @change="handleArea" :disabled="disable">
							<el-option
								v-for="item in areaList"
								:key="item.id"
								:label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="省市" prop="areaId" v-show="typeVisible">
						<el-select size="small" v-model="dataForm.areaId" filterable placeholder="请选择省市" :disabled="disable">
							<el-option
								v-for="item in proviceList"
								:key="item.id"
								:label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</div>
				<div class="flex-1">
					<el-form-item label="经销商名称" prop="name">
						<el-input size="small" v-model="dataForm.name" placeholder="经销商名称" maxlength="32" :disabled="disable"></el-input>
					</el-form-item>
					<el-form-item label="签约级别" prop="contractLevel">
						<el-select size="small" v-model="dataForm.contractLevel" filterable placeholder="请选择签约级别" :disabled="disable" @change="handleContractLevel">
							<el-option
								v-for="item in contractLevelList"
								:key="item.id"
								:label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="签约类型" prop="contractType" v-show="typeVisible">
						<el-select size="small" v-model="dataForm.contractType" filterable multiple placeholder="请选择签约类型" @change="handleContractType" :disabled="disable">
							<el-option
								v-for="item in contractTypeList"
								:key="item.id"
								:label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="省市" prop="areaId" v-show="!typeVisible">
						<el-select size="small" v-model="dataForm.areaId" filterable placeholder="请选择省市" :disabled="disable">
							<el-option
								v-for="item in proviceList"
								:key="item.id"
								:label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</div>
			</div>
			<el-form-item label="经销商说明" prop="remark">
                <el-input type="textarea" :rows="4" v-model="dataForm.remark" resize="none" placeholder="经销商说明" maxlength="256" :disabled="disable"></el-input>
            </el-form-item>
        </el-form>
		<span slot="footer" class="dialog-footer" v-if="!disable">
			<el-button class="submit-btn" @click="submit">确定</el-button>
		</span>
	</el-dialog>
</template>
<script>
import { debounce } from '@/utils/debounce'
export default {
	data() {
		return {
			title: '新增经销商',
			visible: false,
			disable: false,
			submitUrl: 'sysAgentAdd',
			firstFlag: true,
			typeVisible: true,
			areaList: [],
			proviceList: [],
			contractLevelList: [],
			contractTypeList: [],
			dataForm: {
				code: '',
				name: '',
				nameShort: '',
				rangeId: '',
				areaId: '',
				contractLevel: '',
				contractType: [],
				contractTypeName: '',
				remark: ''
			},
			dataRule: {
				code: [
					{required: true,message: '经销商编码不能为空', trigger: "blur"},
					{pattern: /^[A-Za-z0-9_]*$/, message: '经销商编码只能输入数字字母下划线'}
				],
				name: [
					{required: true,message: '经销商名称不能为空', trigger: "blur"}
				],
				rangeId: [
					{required: true,message: '请选择区域', trigger: "change"}
				],
				areaId: [
					{required: true,message: '请选择省市', trigger: "change"}
				],
				contractLevel: [
					{required: true,message: '请选择签约级别', trigger: "change"}
				]
			}
		}
	},
	methods: {
		init(type,data) {
			this.visible = true;
			// 获取省市数据
			this.getList();
			this.proviceList = [];
			this.$nextTick(() => {
				this.$refs.dataForm.resetFields();
				if(type == 'add') {
					this.title = '新增经销商';
					delete this.dataForm.id;
					this.submitUrl = 'sysAgentAdd';
					this.disable = false;
					this.dataForm.areaId = '';
					this.typeVisible = true;
					this.getAutoCodeId();
				}
				else if(type == 'edit') {
					this.title = '修改经销商';
					this.submitUrl = 'sysAgentUpdate';
					this.disable = false;
					this.dataForm.id = data.id;
					this.dataForm.code = data.code;
					this.dataForm.name = data.name;
					this.dataForm.nameShort = data.nameShort;
					this.dataForm.rangeId = data.rangeId ? data.rangeId : '';
					this.dataForm.areaId = data.areaId ? data.areaId : '';
					this.dataForm.contractLevel = data.contractLevel ? data.contractLevel : '';
					this.dataForm.contractType = data.contractType ? data.contractType.split(',') : '';
					this.dataForm.contractTypeName = data.contractTypeName;
					this.dataForm.remark = data.remark;
					this.typeVisible = (data.contractLevelName == '理光总部' || data.contractLevelName == '理光区域') ? false : true;
					this.areaList.forEach(item => {
						if(this.dataForm.rangeId == item.id) {
							this.proviceList = item.areaList;
						}
					})
				}
				else {
					this.title = '查看经销商';
					this.submitUrl = 'sysAgentUpdate';
					this.disable = true;
					this.dataForm.id = data.id;
					this.dataForm.code = data.code;
					this.dataForm.name = data.name;
					this.dataForm.nameShort = data.nameShort;
					this.dataForm.rangeId = data.rangeId ? data.rangeId : '';
					this.dataForm.areaId = data.areaId ? data.areaId : '';
					this.dataForm.contractLevel = data.contractLevel ? data.contractLevel : '';
					this.dataForm.contractType = data.contractType ? data.contractType.split(',') : '';
					this.dataForm.contractTypeName = data.contractTypeName;
					this.dataForm.remark = data.remark;
					this.typeVisible = (data.contractLevelName == '理光总部' || data.contractLevelName == '理光区域') ? false : true;
					this.areaList.forEach(item => {
						if(this.dataForm.rangeId == item.id) {
							this.proviceList = item.areaList;
						}
					})
				}
			})
		},
		// 获取签约级别，地区等数据
		getList() {
			let that = this;
			that.$request.post(
				'getListByCodes',
				true,
				{
					codes: 'contractLevel,contractType,areaType'
				},
				function (r) {
					if (r.code == "0") {
						that.areaList = r.data.areaType;
						that.contractLevelList = r.data.contractLevel;
						that.contractTypeList = r.data.contractType;
						if(that.firstFlag && that.dataForm.rangeId != '') {
							that.firstFlag = false;
							that.areaList.forEach(item => {
								if(that.dataForm.rangeId == item.id) {
									that.proviceList = item.areaList;
								}
							})
						}
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 获取经销商id
		getAutoCodeId() {
			let that = this;
			that.$request.post(
				'getAutoCodeId',
				true,
				{
					code: 'agentCode'
				},
				function (r) {
					if (r.code == "0") {
						that.dataForm.code = r.data.val;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		handleContractLevel(val) {
			let levelName = val ? this.contractLevelList.find(ele => ele.id === val).name : '';
			this.typeVisible = (levelName == '理光总部' || levelName == '理光区域') ? false : true;
			this.dataForm.contractType = '';
			this.dataForm.contractTypeName = '';
		},
		// 签约类型
		handleContractType(val) {
			let arr = [];
			val.forEach(el => {
				arr.push(this.contractTypeList.find(ele => ele.id === el).name)
			})
			this.dataForm.contractTypeName = arr.length > 0 ? arr.join(',') : '';
		},
		// 获取省市数据
		handleArea() {
			this.dataForm.areaId = '';
			this.areaList.forEach(item => {
				if(this.dataForm.rangeId == item.id) {
					this.proviceList = item.areaList;
				}
			})
		},
		submit:debounce(function() {
			if(this.visible == false) {
				return
			}
			this.$refs['dataForm'].validate(valid => {
				if (valid) {
					// if(this.typeVisible && this.dataForm.contractType.length == 0) {
					// 	this.$message.error('请选择签约类型');
					// 	return
					// }
					let submitForm = this.dataForm;
					submitForm.contractType = submitForm.contractType.length > 0 ? this.dataForm.contractType.join(',') : '';
					let that = this;
					that.$request.post(
						that.submitUrl,
						true,
						submitForm,
						function (r) {
							if (r.code == "0") {
								that.visible = false;
								that.$emit('getDealerList');
								that.$message.success('保存成功');
							} else {
								that.$message.error(r.msg);
							}
						}
					);
				} else {
					return false;
				}
			});
		},1000)
	}
}
</script>
<style lang="scss" scoped>
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.el-form-item{
	margin-bottom: 20px;
	.el-select{
		width: 100%;
	}
}
.submit-btn{
	display: block;
	width: 60%;
	margin: 0 auto;
	background-color: #117FFC;
	color: #fff;
}
.role-dialog /deep/ .el-dialog{
	border-radius: 10px !important;
}
.role-dialog /deep/ .el-dialog__body{
	padding: 0 20px 20px !important;
}
.role-dialog /deep/ .el-form-item__label{
	padding: 0 !important;
	line-height: 24px !important;
}
.role-dialog /deep/ .el-dialog__footer{
	padding: 0px 20px 40px !important;
}
.flex{
	display: flex;
}
.flex-1{
	flex: 1;
	box-sizing: border-box;
	padding-right: 15px;
}
</style>
